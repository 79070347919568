@font-face {
  font-family: 'Yay Regular';
  src: url('fonts/Yay-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Yay Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: hidden;
}