.App-header {
  background-color: #002d3b;
  min-height: 100vh;
}

.main-logo-gif {
  position: absolute;
  top: 235px;
  left: 635px;
  height: 650px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
}